<template>
    <div class="padding100" style="min-height: 1200px;">
        <navbar/>
        <side-bar/>
            <v-row style="margin-top: 10px;">
            <v-col col="12" sm="3">
                <v-select v-model="matrice_selected" :items="matrice_id_name" label="Select Matrice" chips persistent-hint @change="getTagAvailable"></v-select>
            </v-col>
            <v-col col="1">
                <div class="row-around">
                <v-btn @click="rechargeData()" style=" margin-top: 20px;" > <v-icon>mdi-sync</v-icon></v-btn>
                <v-btn v-if="show == true"  @click="SupressCurves()" style=" margin-top: 20px; background-color: rgb(172, 42, 68);" > <v-icon>mdi-close</v-icon></v-btn>
                </div>
            </v-col>
            <v-col col="12" sm="3">
                <v-select v-if="this.tag_available.length > 0" v-model="tag_selected" :items="tag_name_available" multiple label="Select Tag" chips persistent-hint @change="switchData" >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 1">
                            <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index < 1" class="text-grey text-caption align-self-center" >
                        (+{{ tag_selected.length - 1 }} ..)
                        </span>
                    </template>
                </v-select>
            </v-col>
            <v-col col="12" sm="3">
                <v-select v-if="showData" v-model="data_tag_selected" :items="data_tag" multiple label="Select Tag Data" chips persistent-hint >
                <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 1">
                    <span>{{ item }}</span>
                    </v-chip>
                    <span v-if="index < 1" class="text-grey text-caption align-self-center" >
                    (+{{ data_tag_selected.length - 1 }} others)
                    </span>
                </template>
                </v-select>
            </v-col>
            <v-col col="1"> 
                <v-btn v-if="data_tag_selected.length && tag_available.length && matrice_selected.length " @click="Validate()" style="background-color: var(--vertfonce); margin-top: 20px;"> Validate </v-btn>
            </v-col>
            </v-row>
            <canir v-if="data_tag_selected.includes('Infrarouge') && show == true" v-bind:tag_id="this.tag_id_r" :tag_id_name="this.tag_id_r_name"/>
            <canr v-if="data_tag_selected.includes('Rouge') && show == true" v-bind:tag_id="this.tag_id_r" :tag_id_name="this.tag_id_r_name"/>
            
            <temp_tag v-if="data_tag_selected.includes('Temperature') && !data_tag_selected.includes('Ammoniaque') && show == true" v-bind:tag_id="this.tag_id_temp" :tag_id_name="this.tag_id_temp_name"/>
            <accxyzvib v-if="data_tag_selected.includes('Vibration') && show == true && matrice_prefix.startsWith('lct_')" v-bind:tag_id="this.tag_id_acc" :tag_id_name="this.tag_id_acc_name"/>
            <accxyzvib v-if="data_tag_selected.includes('Vibration') && show == true && matrice_prefix.startsWith('mat_')" v-bind:tag_id="this.tag_id_acc" :tag_id_name="this.tag_id_acc_name"/>
            <accxyztriple v-if="data_tag_selected.includes('Orientation') && show == true && tag_id_acc_orientation.some(element => element.startsWith('imp_')) " v-bind:tag_id="this.tag_id_acc_orientation" :tag_id_name="this.tag_id_acc_orientation_name"/>
            <luminosite v-if="data_tag_selected.includes('Luminosité') && !data_tag_selected.includes('Humidité') && show == true" v-bind:tag_id="this.tag_id_lumi" :tag_id_name="this.tag_id_lumi_name" />
            <humidite v-if="data_tag_selected.includes('Humidité') && !data_tag_selected.includes('Luminosité') && show == true" v-bind:tag_id="this.tag_id_lumi" :tag_id_name="this.tag_id_lumi_name"/>
            <amonniaque v-if="data_tag_selected.includes('Ammoniaque') && !data_tag_selected.includes('Temperature') && show == true" v-bind:tag_id="this.tag_id_amq" :tag_id_name="this.tag_id_amq_name"/>
            <div v-if="data_tag_selected.includes('Temperature') && data_tag_selected.includes('Ammoniaque') && show == true" >
                <v-row>
                    <v-col cols="12" sm="6">
                        <temp_tag v-bind:tag_id="this.tag_id_temp" :tag_id_name="this.tag_id_temp_name"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <amonniaque v-bind:tag_id="this.tag_id_amq" :tag_id_name="this.tag_id_amq_name"/>
                    </v-col>
                </v-row>
            </div>
            
            <div v-if="data_tag_selected.includes('Luminosité') && data_tag_selected.includes('Humidité') && show == true" >
                <v-row>
                <v-col cols="12" sm="6">
                    <luminosite style="height: 500px;" v-bind:tag_id="this.tag_id_lumi" :tag_id_name="this.tag_id_lumi_name"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <humidite style="height: 500px;" v-bind:tag_id="this.tag_id_lumi" :tag_id_name="this.tag_id_lumi_name"/>
                </v-col>
                </v-row>
            </div>
            <v-dialog v-model="loading" width="500">
                <v-card>
                    <v-card-text style="text-align: center;"> Mise à jour et recherche de matériels présents sur la matrice : {{ matrice_selected2 }}</v-card-text>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card>
            </v-dialog>
    </div>
</template>


<script>
import { switchToken } from '../functions/test-token';
import navbar from '@/components/NavBarCloud.vue';
import SideBar from '../components/SideBar'
import canir from '../components/courbe-can-ir-led.vue'
import canr from '../components/courbe-can-r-led.vue'
import temp_tag from '../components/courbe-temp-tag.vue';
import position from '../components/courbe-position.vue';
import luminosite from '../components/courbe-lumi-tag.vue';
import humidite from '../components/courbe-humi-tag.vue';
import accxyzvib from '../components/acc-xyz-vib.vue';
import accxyztriple from '../components/acc-xyz-triple.vue';
import amonniaque from '../components/courbe-amonniaque.vue';

export default {
	props:["tag_id"],	
    components: {
        navbar,
        SideBar,
        position,
        temp_tag,
        luminosite,
        humidite,
        accxyztriple,
        accxyzvib,
        amonniaque,
        canir,
        canr,
	},
    data() {
        return{
            validate: 0,
            matrice_id:[],
            matrice_id_name:[],
            implant: [],
            implant_name: [],
            capteur_amonniaque : [],
            capteur_amonniaque_name : [],
            capteur_vibration : [],
            capteur_vibration_name :[],
            capteur_sphere : [],
            capteur_sphere_name : [],
            capteur_environnement : [],
            capteur_environnement_name  : [],
            matrice_selected: '',
            matrice_selected2: '',
            tag_id_position: [],
            tag_selected: [],
            tag_and_mat_selected: [],
            tag_id_lumi: [],
            tag_id_lumi_name: [],
            tag_id_temp : [],
            tag_id_temp_name: [],
            tag_id_acc: [],
            tag_id_acc_name : [],
            tag_id_amq: [],
            tag_id_amq_name : [],
            tag_id_r: [],
            tag_id_r_name: [],
            tag_id_acc_orientation: [],
            tag_id_acc_orientation_name: [],
            matrice: [],
            data_tag: [],
            data_tag_selected: [],
            tag_available: [],
            tag_name_available: [],
            tag_prefix: [],
            matrice_prefix: '',
            show: false,
            showData: false,
            loading: false,
        }
    },

    async mounted(){
        await this.getMatriceAvailable();
        await this.GetTags();
    },

    methods:{
        async GetTags(){
            const user = JSON.parse(localStorage.getItem('user'))
            const accessToken = localStorage.getItem('accessToken');
            const email = user.email
            const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            });
            return fetch(url, { headers: headers })
                .then(res => {
                if (res.status === 401) {
                    switchToken().then(() => {
                    // Refaites la requête avec le nouveau token
                        this.GetTags();
                    });
                }
                return res.text();
                })
            .then(async (result) => {
                const data = JSON.parse(result);
                this.implant = data.tag_attributed
                this.implant_name = data.tag_renamed
                this.capteur_amonniaque = data.capteur_amonniaque;
                this.capteur_amonniaque_name = data.amonniaque_renamed;
                this.capteur_vibration = data.capteur_vibration;
                this.capteur_vibration_name = data.vibration_renamed;
                this.capteur_sphere = data.capteur_sphere;
                this.capteur_sphere_name = data.sphere_renamed;
                this.capteur_environnement = data.capteur_environnement;
                this.capteur_environnement_name = data.environnement_renamed;
            })
            .catch(error => {
                console.log(error)
            });
        },
        
        async getTagAvailable() {
            let id_matrice;
            id_matrice = this.matrice_id_name.indexOf(this.matrice_selected);
            const matrice = encodeURIComponent(this.matrice_id[id_matrice]);
            const url = this.$api.getRESTApiUri() + `/real-time/check-tag-available/${matrice}`;
            const accessToken = localStorage.getItem('accessToken');
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            });
            return fetch(url, { headers: headers })
                .then(res => {
                    if (res.status === 401) {
                    switchToken().then(() => {
                        // Refaites la requête avec le nouveau token
                        this.getTagAvailable();
                    });
                }
                return res.text();
              })
              .then((result) => {
                const data = JSON.parse(result)
                // Filtrer les éléments du tableau data.tag_available
                this.tag_available = data.tag_available.filter(tag => {
                    // Vérifier si le tag est présent dans au moins un des tableaux cibles
                    return (
                        this.capteur_amonniaque.includes(tag) ||
                        this.capteur_vibration.includes(tag) ||
                        this.capteur_sphere.includes(tag) ||
                        this.capteur_environnement.includes(tag) ||
                        this.implant.includes(tag)
                    );
                });
                this.tag_name_available = [];
                for(let i =0; i < this.tag_available.length; i++){
                    if(this.tag_available[i].startsWith('imp_')){
                        if(this.implant_name[this.implant.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.implant_name[this.implant.indexOf(this.tag_available[i])])
                        }
                    } if(this.tag_available[i].startsWith('ppg1_')){
                        if(this.implant_name[this.implant.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.implant_name[this.implant.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('env_')){
                        if(this.capteur_environnement_name[this.capteur_environnement.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_environnement_name[this.capteur_environnement.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('vib_')){
                        if(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('vib2_')){
                        if(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('sph_') || this.tag_available[i].startsWith('spha_') ){
                        if(this.capteur_sphere_name[this.capteur_sphere.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_sphere_name[this.capteur_sphere.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('amq_')){
                        if(this.capteur_amonniaque_name[this.capteur_amonniaque.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_amonniaque_name[this.capteur_amonniaque.indexOf(this.tag_available[i])])
                        }
                    }
                
                }
            })
            .catch((error) => {
                console.log(error)
            });
        },

        async getMatriceAvailable(){
            const user = JSON.parse(localStorage.getItem('user'))
            const accessToken = localStorage.getItem('accessToken');
            const email = user.email
            const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            });
            return fetch(url, { headers: headers })
                .then(res => {
                if (res.status === 401) {
                    switchToken().then(() => {
                    // Refaites la requête avec le nouveau token
                        this.getMatriceAvailable();
                    });
                }
                return res.text();
                })
            .then(async (result) => {
                const data = JSON.parse(result);
                
                this.matrice_id = data.matrice_attributed
                this.matrice_name = data.matrice_renamed
                if (this.matrice_id && this.matrice_id.length > 0) {
                    for(let i=0 ; i< this.matrice_id.length ; i++){
                    if(this.matrice_name[i] == "noname"){
                        this.matrice_id_name.push(this.matrice_id[i])
                    }else{
                        this.matrice_id_name.push(this.matrice_name[i])
                    }
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },

        async Validate(){
            const tag_selected_real = []
            for(let i = 0; i < this.tag_selected.length; i++){
                tag_selected_real.push(this.tag_available[this.tag_name_available.indexOf(this.tag_selected[i])])
            }
            console.log(tag_selected_real)
            tag_selected_real.sort(function (a, b) {
                // Fonction de comparaison personnalisée
                const order = { 'imp_': 1, 'ppg1': 2, 'env_': 3,  'vib2': 4, 'vib_': 5, 'sph_': 6, 'amq_': 7 };
                const aPrefix = a.substring(0, 4);
                const bPrefix = b.substring(0, 4);
                
                return order[aPrefix] - order[bPrefix];
            });
            console.log(tag_selected_real)
            this.tag_id_lumi =  tag_selected_real.filter((tag) => tag.startsWith("env_"));
            this.tag_id_temp =  tag_selected_real.filter((tag) => tag.startsWith("env_") || tag.startsWith("imp_") || tag.startsWith("ppg1"));
            this.tag_id_acc =  tag_selected_real.filter((tag) => tag.startsWith("imp_") || tag.startsWith("ppg1") || tag.startsWith("vib_") || tag.startsWith("vib2") || tag.startsWith("spha") );
            this.tag_id_acc_orientation = tag_selected_real.filter((tag) => tag.startsWith("imp_"));
            this.tag_id_position =  tag_selected_real.filter((tag) => tag.startsWith("imp_") || tag.startsWith("ppg1") || tag.startsWith("sph_") || tag.startsWith("vib_") || tag.startsWith("vib2") || tag.startsWith("env_") || tag.startsWith("amq_") );
            this.tag_id_amq =  tag_selected_real.filter((tag) => tag.startsWith("amq_"));
            this.tag_id_r =  tag_selected_real.filter((tag) => tag.startsWith("ppg1"));
            for(let i = 0; i< this.tag_id_lumi.length; i++){
                this.tag_id_lumi_name.push(this.tag_name_available[this.tag_available.indexOf(this.tag_id_lumi[i])])
            }
            for(let i = 0; i< this.tag_id_temp.length; i++){
                this.tag_id_temp_name.push(this.tag_name_available[this.tag_available.indexOf(this.tag_id_temp[i])])
            }
            for(let i = 0; i< this.tag_id_acc.length; i++){
                this.tag_id_acc_name.push(this.tag_name_available[this.tag_available.indexOf(this.tag_id_acc[i])])
            }
            for(let i = 0; i< this.tag_id_amq.length; i++){
                this.tag_id_amq_name.push(this.tag_name_available[this.tag_available.indexOf(this.tag_id_amq[i])])
            }
            for(let i = 0; i< this.tag_id_r.length; i++){
                this.tag_id_r_name.push(this.tag_name_available[this.tag_available.indexOf(this.tag_id_r[i])])
            }
            for(let i = 0; i< this.tag_id_acc.length; i++){
                this.tag_id_acc_orientation_name.push(this.tag_name_available[this.tag_available.indexOf(this.tag_id_acc_orientation[i])])
            }
            console.log(this.tag_id_temp_name);
            this.show = true;
        },

        async switchData(){
            if (this.tag_name_available.length === 0) {
                return; // Sortez de la fonction pour éviter l'erreur.
            }
            const lastSelectedTag = this.tag_selected[this.tag_selected.length - 1];
            
            // Vérifiez si lastSelectedTag existe dans tag_name_available.
            if (!this.tag_name_available.includes(lastSelectedTag)) {
                return; // Sortez de la fonction si l'élément n'existe pas.
            }
            this.tag_prefix = this.tag_available[this.tag_name_available.indexOf(this.tag_selected[this.tag_selected.length-1])]
            this.matrice_prefix = this.matrice_id[this.matrice_id_name.indexOf(this.matrice_selected)]
            
            if(this.tag_prefix.startsWith('ppg1') || this.tag_prefix.startsWith('imp_')   ){
                this.data_tag.push( 'Vibration', 'Orientation', 'Temperature')
                if(this.matrice_prefix.startsWith('lct_') && this.tag_prefix.startsWith('ppg1')){
                    this.data_tag.push('Infrarouge', 'Rouge')
                }
            }
            if(this.tag_prefix.startsWith('env_')){
                this.data_tag.push( 'Temperature', 'Luminosité', 'Humidité')
            }
            if(this.tag_prefix.startsWith('vib_') || this.tag_prefix.startsWith('vib2_') || this.tag_prefix.startsWith('spha')){
                this.data_tag.push( 'Vibration',)
            }
            if(this.tag_prefix.startsWith('amq_')){
                this.data_tag.push( 'Ammoniaque')
            }
            
            this.showData = true
        },

        async rechargeData(){
            this.tag_available = [],
            this.matrice_selected2 = this.matrice_selected;
            if(this.matrice_selected2 == ''){
                this.matrice_selected2 = 'pas de matrice sélectionnée'
            }
            this.loading = true;
            if (this.matrice_selected !== ''){
                let id_matrice;
                id_matrice = this.matrice_id_name.indexOf(this.matrice_selected)
                const matrice = encodeURIComponent(this.matrice_id[id_matrice])
                const url = this.$api.getRESTApiUri() + `/reload/tag_available/`
                await fetch(url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                                matrice: matrice,
                            }),
                    })
            }
            setTimeout(async () => {
                this.loading = false; // Arrêtez le chargement après 5 secondes
                await this.getTagAvailable();
            }, 5000);

            this.show = false;
            this.tag_id_position = [];
            this.tag_id_r = [];
            this.tag_id_r_name = [];
            this.tag_id_acc = [];
            this.tag_id_acc_name = [];
            this.tag_id_temp = [];
            this.tag_id_temp_name = [];
            this.tag_id_lumi = [];
            this.tag_id_lumi_name = [];
            this.tag_id_amq = [];
            this.tag_id_amq_name = [];
            this.tag_selected = [];
            this.tag_and_mat_selected = [];
            this.data_tag_selected = [];
            this.data_tag = [];
            this.matrice_selected = [];
        },
        async SupressCurves(){
            this.show = false;
            this.tag_id_position = [];
            this.tag_id_r = [];
            this.tag_id_r_name = [];
            this.tag_id_acc = [];
            this.tag_id_acc_name = [];
            this.tag_id_temp = [];
            this.tag_id_temp_name = [];
            this.tag_id_lumi = [];
            this.tag_id_lumi_name = [];
            this.tag_id_amq = [];
            this.tag_id_amq_name = [];
            this.tag_selected = [];
            this.tag_and_mat_selected = [];
            this.data_tag_selected = [];
            this.data_tag = [];
            this.matrice_selected = [];
        },
    }   
}

</script>