import { Line } from "vue-chartjs";
import 'chartjs-plugin-streaming';

export default {
	extends: Line,
	props: {
		label: {
			type: String
		},
		chartData: {
			type: Array
		},
		options: {
			type: Object
		},
		chartColors: {
			type: Object
		},
		tag_id_name:{
			type: Array
		},
		pause:{
			type: Boolean
		},
		vib:{
			type: Boolean
		}
	},
	
	mounted() {
		const datasets = [];
		for(let i =0; i < this.tag_id_name.length; i++){
			datasets.push({
				label: this.tag_id_name[i],
				data: [],
				fill: false,
				borderColor: this.chartColors.borderColor,
				backgroundColor:  this.chartColors.backgroundColor,
				tension: 0.1,
				pointRadius: 1.0,
				borderWidth: 1.0,
			})
		}

		const chartOptions = {
			...this.options,
			plugins: {
				streaming: {
				...this.options.plugins.streaming,
				pause: this.pause,
				},
			},
			};

			this.renderChart({
			datasets: datasets
			}, chartOptions);
	},
	  watch: {
		pause(newPause) {
		// Mettre à jour la valeur de l'option "pause" et mettre à jour le graphique
		this.$data._chart.config.options.plugins.streaming.pause = newPause;
		this.$data._chart.update();
		}
	},
};