<template>
  <div style="margin-top: 15px;">
    <v-col>
      <div class="box-curves">
        <v-row>
          <v-col cols="12" sm="12">
            <v-card-title class="row-around" style="width: 100%;">
				<h4 style="text-align: left;width: 20%;" class="text-h6 font-weight-black">Orientation Axe X Y Z(g)</h4>
				<div style="width: 60%;" class="row-around">
					<span class="hide-label" :class="{ 'show-label': showPrev }" v-if="tag_id.length > 1">Précédent</span>
					<button class="arrow-btn" @click="minus1" @mouseover="showPrev = true" @mouseout="showPrev = false" v-if="tag_id.length > 1">
						<v-icon>mdi-arrow-left</v-icon>
					</button>
					<h4 class="text-h6 font-weight-black" style="text-align: center; vertical-align: middle;"> {{ tag_id_name[tagToView] }}</h4>
					<button class="arrow-btn" @click="add1" @mouseover="showNext = true" @mouseout="showNext = false" v-if="tag_id.length > 1">
						<v-icon>mdi-arrow-right</v-icon>
					</button>
					<span class="hide-label" :class="{ 'show-label': showNext } " v-if="tag_id.length > 1">Suivant</span>
				</div>
				<div style="width: 20%;">
				</div>
				
            </v-card-title>
          </v-col>
		  <v-col cols="12" sm="12" class="row-center">
			<v-card-actions>
					<v-btn @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</v-btn>
			</v-card-actions>
			</v-col>
        </v-row>
        <line-chart-XYZ :options="axeXChartOptions"
          :chartColors="yellowXChartColors" label="Axe X" :tag_id_name="tag_id_name" :pause="isPaused"/>
        <br>
	</div>
    </v-col>
  </div>
</template>


<script>
import LineChartXYZ from "../components/LineChartXYZ.js";
import { getDataLastId } from '../functions/get-data'

export default {
	props:["tag_id", "tag_id_name"],
    name: 'accxyz',
    components: {
			LineChartXYZ,
		},

    data() {
        return {
			 showPrev: false, // Pour afficher "Précédent" au survol du bouton gauche
      		showNext: false, // Pour afficher "Suivant" au survol du bouton droit
			tagToView : 0,
			id_memo_x: [],
			id_memo_y: [],
			id_memo_z: [],
			isPaused: false,
            yellowXChartColors: {
                borderColor: 'rgb(97, 194, 179)',
                backgroundColor: 'rgba(97, 194, 179, 0.2)'
            },
            // ===============================  CHART ACC-X ===============================
            axeXChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						streaming: {
							frameRate: 30,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 250,
								delay: 2000,
								onRefresh: (chart) => {
										this.getAxeX().then((newData) => {
										    chart.data.datasets[0].data.push(...newData);
										}).catch((e) => {
										console.log(e);
										});
                                        this.getAxeY().then((newData) => {
										    chart.data.datasets[1].data.push(...newData);
										}).catch((e) => {
										console.log(e);
										});
                                        this.getAxeZ().then((newData) => {
											chart.data.datasets[2].data.push(...newData);
										}).catch((e) => {
											console.log(e);
										});
								},
							},
							
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
                
			};
		},
		methods: {
			async getAxeX() {
				return this.getAxeData('x');
			},
			async getAxeY() {
				return this.getAxeData('y');
			},
			async getAxeZ() {
				return this.getAxeData('z');
			},
            async getAxeData(axis) {
				try {
					const tag_id = encodeURIComponent(this.tag_id[this.tagToView]);
					const data = await getDataLastId(tag_id)
					let newData = [];
					if (data[0][`acc_${axis}_tag`].length === 0) {
						return [];
					}
					if (data[0].id <= this[`id_memo_${axis}`][this.tagToView]) {
						newData.push({ x: Date.now() });
					} else {
						let somme1 = 0, somme2 = 0;
						let tailleMoitieTableau1 = 0, tailleMoitieTableau2 = 0;
						for (let i = 0; i < data[0][`acc_${axis}_tag`].length; i++) {
							if (typeof data[0][`acc_${axis}_tag`][i] === "number") {
								if (i < data[0][`acc_${axis}_tag`].length / 2) {
									somme1 += data[0][`acc_${axis}_tag`][i];
									tailleMoitieTableau1++;
								} else {
									somme2 += data[0][`acc_${axis}_tag`][i];
									tailleMoitieTableau2++;
								}
							}
						}
						const moyenne1 = tailleMoitieTableau1 > 0 ? somme1 / tailleMoitieTableau1 : 0;
						const moyenne2 = tailleMoitieTableau2 > 0 ? somme2 / tailleMoitieTableau2 : 0;
						newData.push(
							{ x: Date.now(), y: moyenne1 },
							{ x: Date.now() + 125, y: moyenne2 }
						);
					}
					this[`id_memo_${axis}`][this.tagToView] = data[0].id;
					return newData;
				} catch (error) {
					console.error("An error occurred:", error);
					return []; // Return an empty array or other error handling output
				}
			},
			togglePause() {
				this.isPaused = !this.isPaused;
			},
			add1(){
				this.tagToView++
				if(this.tagToView > this.tag_id.length-1){
					this.tagToView = 0;
				}
			},
			minus1(){
				this.tagToView--
				if(this.tagToView < 0){
					this.tagToView = this.tag_id.length-1;
				}
			}
		}
	};
</script>

<style scoped>
.arrow-btn{
	background-color: var(--bleu);
	border-radius: 5px;
	padding: 0px 10px;
	border: solid 2px var(--bleu) ;
}
.arrow-btn:hover{
	background-color: var(--bleu), 0.1;
	transition-duration: 0.5s;
}
.hide-label {
  opacity: 0;
  transition: opacity 0.5s;
}

.show-label {
  opacity: 1;
}

</style>